.workcontainer{
  color: white;
  font-family: 'Roboto', sans-serif;
}

.topic{
  margin-left: 245px;
}

.topic p{
  width: 70%;
}

.note{
  margin-left: 211px;
  /* word-wrap: inherit; */
  text-align: center;
  width: 70%;
}

#test{
  display: none;
}

.project{
  width: 70%;
  text-align: center;
  margin-left: 211px;
  margin-bottom: 40px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project iframe{
  width: 1000px;
  height: 500px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Specific styles for AI Map Generator iframe */
.project iframe[title="AI Map Generator"] {
  height: 600px;
  background: #1A1A1A;
}

.project img{
  width: 1000px;
  height: 700px;
}

.project ul {
  text-align: left;
  margin: 20px auto;
  width: 80%;
}

.project ul li {
  margin: 10px 0;
}

.project a {
  color: #4553FE;
  text-decoration: none;
  transition: color 0.3s ease;
}

.project a:hover {
  color: #6C63FF;
}

@media screen and (max-width: 768px){
  .workcontainer{
    color: white;
    text-align: center;
    overflow-x: hidden;
  }
  
  .topic{
    margin-left: 10px;
  }
  
  .project{
    width: 90%;
    text-align: center;
    margin-left: 20px;
    padding: 15px;
  }

  .project iframe{
    width: 100%;
    height: 400px;
    margin-right: 0;
  }

  .project iframe[title="AI Map Generator"] {
    height: 500px;
  }

  .project img{
    width: 100%;
    height: auto;
    margin-right: 0;
  }

  .topic{
    font-size: 10px;
    text-align: center;
    margin-left: 10px;
  }

  .note{
    text-align: center;
  }
}

@media screen and (max-device-width: 320px){
  .project{
    width: 90%;
    text-align: center;
    margin-left: 15px;
    padding: 10px;
  }

  .project iframe{
    width: 100%;
    height: 300px;
  }

  .project iframe[title="AI Map Generator"] {
    height: 400px;
  }

  .project img{
    width: 100%;
    height: auto;
  }

  .project p{
    font-size: 12px;
  }

  .project h2{
    font-size: 17px;
  }

  .topic p{
    font-size: 10px;
    text-align: center;
    margin-left: 10px;
    width: 80%;
  }
}